import React from 'react';
import cx from 'classnames';

import { ModuleWrapper } from '../moduleWrapper';
import { SanityImage } from '@components/sanity/sanityImage';

const SingleImage = ({ config, image, layout = 'center' }) => {
  return (
    <ModuleWrapper {...config} className="grid grid-cols-14">
      <div
        className={cx({
          'col-start-1 col-end-15': layout === 'full',
          'col-start-2 col-end-14': layout === 'center',
          'col-start-2 col-end-14 md:col-end-8': layout === 'drop-left',
          'col-start-2 col-end-14 md:col-start-8': layout === 'drop-right',
          'col-start-1 col-end-15 md:col-end-9': layout === 'left',
          'col-start-1 col-end-15 md:col-start-8': layout === 'right',
        })}
      >
        <SanityImage image={image} width={2000} />
      </div>
    </ModuleWrapper>
  );
};

export default SingleImage;
